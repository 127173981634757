var jQuery = require('jQuery');


jQuery(function ($) {

  // Recruit Contents Button Click Function.
  function recruitBtnClick() {

    // Variable Definition.
    var arrowRight = $('a#arrowRight'),
      arrowLeft = $('a#arrowLeft'),
      navBtn = $('#recruit nav ul li'),
      inner = document.getElementById('recruitInner'),
      innerW = inner.clientWidth,
      innerContentsCount = document.querySelectorAll('#recruitInner > .contents'),
      innerContentsLEN = innerContentsCount.length,
      math = innerW / innerContentsLEN,
      limit = innerW - math,
      ary = [],
      val = 0;


    // Navgation Button Function.
    navBtn.on('click', function (event) {

      var _this = $(this),
        bodyID = $('body').attr('id');

      navBtn.removeClass('on');
      _this.addClass('on');

      // PC's Function.
      if(bodyID === 'pc') {

        var index = navBtn.index(this);

        val = math * index;

        inner.style.webkitTransform = 'translateX( -' + val + 'px )';
        inner.style.MozTransform = 'translateX( -' + val + 'px )';
        inner.style.msTransform = 'translateX( -' + val + 'px )';
        inner.style.OTransform = 'translateX( -' + val + 'px )';
        inner.style.transform = 'translateX( -' + val + 'px )';

        if(val === limit) {
          arrowRight.addClass('off');
        }
        if(val > 0) {
          arrowLeft.removeClass('off');
        }
        if(val === 0) {
          arrowLeft.addClass('off');
        }
        if(val < limit) {
          arrowRight.removeClass('off');
        }

        // SP's Funcition.
      } else if(bodyID === 'sp') {
        var getID = _this.attr('id'),
          getContents = $('[data-recruit="' + getID + '"]'),
          getInner = $('#recruitInner').children('.contents');
        getInner.removeClass('on').addClass('off');
        getContents.addClass('on').removeClass('off');
        //console.log(getID);
        return false;
      }
      event.preventDefault();
    });

    // Right Arrow Click Function.
    arrowRight.on('click', function () {

      val += math;

      inner.style.webkitTransform = 'translateX( -' + val + 'px )';
      inner.style.MozTransform = 'translateX( -' + val + 'px )';
      inner.style.msTransform = 'translateX( -' + val + 'px )';
      inner.style.OTransform = 'translateX( -' + val + 'px )';
      inner.style.transform = 'translateX( -' + val + 'px )';

      if(val === limit) {
        $(this).addClass('off');
      }
      if(val > 0) {
        arrowLeft.removeClass('off');
      }

      //console.log('limit is ' + limit);
      //console.log('checked value ' + val);
      //console.log('test ' + val / math);

      var navON = val / math;
      navBtn.removeClass('on');
      navBtn.eq(navON).addClass('on');

      return false;
    });

    // Left Arrow Click Function.
    arrowLeft.on('click', function () {

      val -= math;

      inner.style.webkitTransform = 'translateX( -' + val + 'px )';
      inner.style.MozTransform = 'translateX( -' + val + 'px )';
      inner.style.msTransform = 'translateX( -' + val + 'px )';
      inner.style.OTransform = 'translateX( -' + val + 'px )';
      inner.style.transform = 'translateX( -' + val + 'px )';

      if(val === 0) {
        $(this).addClass('off');
      }
      if(val < limit) {
        arrowRight.removeClass('off');
      }

      //console.log('limit is ' + limit);
      //console.log('checked value ' + val);
      //console.log('test ' + val / math);

      var navON = val / math;
      navBtn.removeClass('on');
      navBtn.eq(navON).addClass('on');

      return false;
    });

  }

  recruitBtnClick();


  // Andes Man Change the Displayed by Time Function.
  // Variable Definition.
  var todayObject = new Date(),
    nowHours = todayObject.getHours(),
    nowMinutes = todayObject.getMinutes();
  if(nowHours >= 5 && nowHours < 10) {
    document.getElementById('andesManMorning').style.display = 'block';
  } else if(nowHours === 12 && nowMinutes >= 0 && nowMinutes < 11) {
    document.getElementById('andesManLunch').style.display = 'block';
  } else if(nowHours === 22 && nowMinutes >= 0 && nowMinutes < 31) {
    document.getElementById('andesManSleep').style.display = 'block';
  } else {
    document.getElementById('andesManDefault').style.display = 'block';
  }


  // Works Tab Click Functions.
  function worksTab() {
    // Created Random Number Function.
    function randomNumber(array, num) {
      var a = array;
      var t = [];
      var r = [];
      var l = a.length;
      var n = num < l ? num : l;
      while(n-- > 0) {
        var i = Math.random() * l | 0;
        r[n] = t[i] || a[i];
        --l;
        t[i] = t[l] || a[l];
      }
      return r;
    }

    function init() {
      // For Init Variable Definition.
      var initGetDOM = $('#works .contents:not(.on)').find('.box'),
        initDomLEN = initGetDOM.length,
        randomClass = ['onScale', 'onSkew', 'onRotate', 'onRotateX', 'onRotateY'],
        classLEN = randomClass.length,
        initAry = [];

      // Putting Init DOM in the Array.
      for(var i = 0; i < initDomLEN; i++) {
        initAry.push(i);
      }

      // Random Number Generation. Using Random Number Function.
      var initNumArray = randomNumber(initAry, initDomLEN);

      // Init Random Add Class.
      for(var i in initNumArray) {
        initGetDOM.eq(initNumArray[i]).queue(function () {
          var classIndex = Math.floor(Math.random() * classLEN);
          $(this).addClass(randomClass[classIndex]).dequeue();
        });
      }
    }

    init();

    // Tab Click Functions.
    $('.tab').on('click', function () {

      // Required Variable Definition.
      var _this = $(this),
        getID = _this.attr('id'),
        getContents = $('[data-tab="' + getID + '"]'),
        getDOM = getContents.children('.box'),
        target = document.querySelectorAll('[data-tab="' + getID + '"] > .box'),
        domLEN = target.length,
        ary = [],
        thumbnail;

      // Created Necessary Functions.
      thumbnail = {
        // Init Function.
        clickInit: function () {
          $('.tab').removeClass('on');
          $('#works .contents').removeClass('on');
          getDOM.clearQueue();
          getDOM.removeClass('on last');
        },
        // Added Class Funcition.
        addedClass: function () {
          _this.addClass('on');
          getContents.addClass('on');
          $('.tab').addClass('unClick');
        }
      }

      // Using Init & Added Class Functions.
      thumbnail.clickInit();
      thumbnail.addedClass();

      // Putting Target DOM in the Array.
      for(var i = 0; i < domLEN; i++) {
        ary.push(i);
      }

      // Random Number Generation. Using Random Number Function & Added Class 'last'. for Last Animation.
      var numArray = randomNumber(ary, domLEN),
        last = Math.floor(Math.random() * domLEN);

      getDOM.eq(last).removeClass('onScale onSkew onRotate onRotateX onRotateY').addClass('last').dequeue();

      // Show Thumbnail Animation Function.
      setTimeout(function () {
        for(var i in numArray) {
          getDOM.eq(numArray[i]).delay(i * 75).queue(function () {
            $(this).removeClass('onScale onSkew onRotate onRotateX onRotateY').dequeue();
          });
        }
        setTimeout(function () {
          getDOM.eq(last).removeClass('last');
          setTimeout(function () {
            $('.tab').not(_this).removeClass('unClick');
            init();
          }, 850);
        }, i * 150);
      }, 10);
    });
  }

  worksTab();


  // Company Button Click Function.
  function companyBTN(event) {
    $('.btn').on('click', function () {
      var _this = $(this),
        getID = _this.attr('id'),
        getContents = $('[data-btn="' + getID + '"]'),
        bodyID = $('body').attr('id');

      btn = {
        init: function () {
          $('.btn').removeClass('on');
          $('#company .wrap .inner').removeClass('on');
        },
        addedClass: function () {
          _this.addClass('on');
          getContents.addClass('on');
        },
        switchClass: function () {
          _this.toggleClass('on');
          getContents.slideToggle();;
        }
      };
      if(bodyID === 'pc') {
        btn.init();
        btn.addedClass();
      } else if(bodyID === 'sp') {
        btn.switchClass();
        return false;
      }
      event.preventDefault();
    });
  }

  companyBTN();

  // Footer Nav Click Function.
  $('footer li a').on('click', function () {
    var target = $(this.hash);
    if(target) {
      var targetOffset = target.offset().top;
      $('html,body').animate({
        scrollTop: targetOffset
      }, 400, 'swing');
      return false;
    }
  });

  // Check UA Function.
  var checkUA = function () {
    var ua = navigator.userAgent;
    if(ua.indexOf('iPhone') > -1 || ua.indexOf('iPod') > -1 || ua.indexOf('Android') > -1) {
      $("meta[name='viewport']").attr('content', '').attr('content', 'width=750, user-scalable=no');
    } else if(ua.indexOf('iPad') > -1) {
      $("meta[name='viewport']").attr('content', '').attr('content', 'width=960, user-scalable=no');
    } else {
      $("meta[name='viewport']").attr('content', '').attr('content', 'width = device-width, initial-scale = 1.0, minimum-scale = 1.0');
    }
  }
  window.addEventListener('resize', checkUA, false);
  window.addEventListener('load', checkUA, false);
  window.addEventListener('DOMContentLoaded', checkUA, false);

  // <body></body> Set ID Function.
  var setID = function () {
    var ua = navigator.userAgent,
      getW = window.innerWidth,
      setPC = (ua.indexOf('Android') === -1) ? 751 : 752,
      setSP = (ua.indexOf('Android') === -1) ? 750 : 751;
    ////console.log(getW);
    ////console.log(setSP);
    if(getW >= setPC) {
      document.body.id = 'pc';
      ////console.log('pc');
    } else if(getW <= setSP) {
      document.body.id = 'sp';
      ////console.log('sp');
    }
  }
  window.addEventListener('resize', setID, false);
  window.addEventListener('load', setID, false);
  window.addEventListener('DOMContentLoaded', setID, false);

  // Contents Init Function.
  var responsiveInit = function () {
    var bodyID = $('body').attr('id'),
      addCheckDOM = $('main');
    if(bodyID === 'pc') {
      addCheckDOM.addClass('checkFlag');
    } else if(bodyID === 'sp') {
      addCheckDOM.removeClass('checkFlag');
    }
  }
  window.addEventListener('load', responsiveInit, false);
  window.addEventListener('DOMContentLoaded', responsiveInit, false);

  // Resize Init Function.
  var flag = '';
  $(window).on('resize', function () {
    resizeInit();
  });

  function resizeInit() {
    var bodyID = $('body').attr('id'),
      target = $('main'),
      ua = navigator.userAgent;

    if(bodyID === 'pc' && flag != 'pc') {
      flag = 'pc';
      target.addClass('checkFlag')
    } else if(bodyID === 'sp' && flag != 'sp') {
      flag = 'sp';
      target.removeClass('checkFlag')
    }
    if(ua.indexOf('iPhone') === -1 && ua.indexOf('iPod') === -1 && ua.indexOf('Android') === -1 && ua.indexOf('iPad') === -1) {
      target.on('DOMSubtreeModified propertychange', function () {
        location.reload();
      });
    }
  }


  // Displayed First View. (#AndesMan)
  var FV = function () {

    var FV_DOM = document.getElementById('andesMan'),
      WH = window.innerHeight,
      FV_posi_Y = FV_DOM.getBoundingClientRect(),
      scVal = window.pageYOffset,
      setPosi = FV_posi_Y.top + scVal;
    //console.log(WH);
    //console.log(setPosi);

    // Added Height.
    FV_DOM.style.minHeight = WH + 'px';

    // Set Position.
    setTimeout(function () {
      window.scrollTo(0, setPosi);
    }, 50);
    $('body').addClass('on');
  }

  window.addEventListener('load', FV, false);

});
